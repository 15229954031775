import { default as _91_46_46_46page_93plMEWPrPAfMeta } from "/vercel/path0/pages/[...page].vue?macro=true";
import { default as apartmentsx1qXLN83WvMeta } from "/vercel/path0/pages/apartments.vue?macro=true";
import { default as contact_45successJtmrpHVWIjMeta } from "/vercel/path0/pages/contact-success.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as faqM4LN7jxnMuMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as galleryjDl5RbNP1cMeta } from "/vercel/path0/pages/gallery.vue?macro=true";
import { default as hotelsXE5J31W3vVMeta } from "/vercel/path0/pages/hotels.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91item_93cNkC3d8yUhMeta } from "/vercel/path0/pages/magazine/[item].vue?macro=true";
import { default as index450GWosCS5Meta } from "/vercel/path0/pages/magazine/index.vue?macro=true";
import { default as _91item_93VS61Z44hIgMeta } from "/vercel/path0/pages/packages/[item].vue?macro=true";
import { default as index1us1AaJFa5Meta } from "/vercel/path0/pages/packages/index.vue?macro=true";
import { default as restaurantsIhtYhb3P7AMeta } from "/vercel/path0/pages/restaurants.vue?macro=true";
import { default as sitemapmtRscZaA8vMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
import { default as _91item_93zKgFSelgFtMeta } from "/vercel/path0/pages/vacancies/[item].vue?macro=true";
import { default as indexp3qWQtgtPJMeta } from "/vercel/path0/pages/vacancies/index.vue?macro=true";
export default [
  {
    name: "page___nl",
    path: "/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___en",
    path: "/en/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "apartments___nl",
    path: "/appartementen",
    component: () => import("/vercel/path0/pages/apartments.vue")
  },
  {
    name: "apartments___en",
    path: "/en/apartments",
    component: () => import("/vercel/path0/pages/apartments.vue")
  },
  {
    name: "contact-success___nl",
    path: "/contact-form-success",
    component: () => import("/vercel/path0/pages/contact-success.vue")
  },
  {
    name: "contact-success___en",
    path: "/en/contact-form-success",
    component: () => import("/vercel/path0/pages/contact-success.vue")
  },
  {
    name: "contact___nl",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "faq___nl",
    path: "/veelgestelde-vragen",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___en",
    path: "/en/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "gallery___nl",
    path: "/galerie",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "gallery___en",
    path: "/en/gallery",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "hotels___nl",
    path: "/hotels",
    component: () => import("/vercel/path0/pages/hotels.vue")
  },
  {
    name: "hotels___en",
    path: "/en/hotels",
    component: () => import("/vercel/path0/pages/hotels.vue")
  },
  {
    name: "index___nl",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "magazine-item___nl",
    path: "/vondel-magazine/:item()",
    component: () => import("/vercel/path0/pages/magazine/[item].vue")
  },
  {
    name: "magazine-item___en",
    path: "/en/vondel-magazine/:item()",
    component: () => import("/vercel/path0/pages/magazine/[item].vue")
  },
  {
    name: "magazine___nl",
    path: "/vondel-magazine",
    component: () => import("/vercel/path0/pages/magazine/index.vue")
  },
  {
    name: "magazine___en",
    path: "/en/vondel-magazine",
    component: () => import("/vercel/path0/pages/magazine/index.vue")
  },
  {
    name: "packages-item___nl",
    path: "/arrangementen/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue")
  },
  {
    name: "packages-item___en",
    path: "/en/special-offers/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue")
  },
  {
    name: "packages___nl",
    path: "/arrangementen",
    component: () => import("/vercel/path0/pages/packages/index.vue")
  },
  {
    name: "packages___en",
    path: "/en/special-offers",
    component: () => import("/vercel/path0/pages/packages/index.vue")
  },
  {
    name: "restaurants___nl",
    path: "/restaurants",
    component: () => import("/vercel/path0/pages/restaurants.vue")
  },
  {
    name: "restaurants___en",
    path: "/en/restaurants",
    component: () => import("/vercel/path0/pages/restaurants.vue")
  },
  {
    name: "sitemap___nl",
    path: "/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___en",
    path: "/en/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "vacancies-item___nl",
    path: "/jobs/:item()",
    component: () => import("/vercel/path0/pages/vacancies/[item].vue")
  },
  {
    name: "vacancies-item___en",
    path: "/en/jobs/:item()",
    component: () => import("/vercel/path0/pages/vacancies/[item].vue")
  },
  {
    name: "vacancies___nl",
    path: "/jobs",
    component: () => import("/vercel/path0/pages/vacancies/index.vue")
  },
  {
    name: "vacancies___en",
    path: "/en/jobs",
    component: () => import("/vercel/path0/pages/vacancies/index.vue")
  }
]